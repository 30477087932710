import { create } from "zustand";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  isLoading: false,
  accountId: null, // For some reason gets reset when page is reloaded (opting for local storage saving)
  sidebarIsCollapsed: true,
};

const useAccountStore = create((set) => ({
  ...store,
  setLoading: (isLoading) => set(() => ({ isLoading: isLoading })),
  setAccountId: (id) => set(() => ({ accountId: id })),
  setSidebarIsCollapsed: (sidebarIsCollapsed) =>
    set(() => ({ sidebarIsCollapsed: sidebarIsCollapsed })),
  reset: () => set({ ...store }),
}));

export { useAccountStore };
