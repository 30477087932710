import { SupplierService } from "../service/supplier.service";
import { useSupplierStore } from "../store";
import { showError } from "../widgets";

export function getSuppliers() {
  const setSuppliers = useSupplierStore.getState().setSuppliers;
  SupplierService.getSupplierData()
    .then((data) => {
      const suppliersData = data.map((supplier) => ({
        ...supplier,
        ...supplier.address,
        address: `${supplier.address.house_no}, ${supplier.address.area}, ${supplier.address.city}, ${supplier.address.pincode}`,
      }));
      setSuppliers(suppliersData);
    })
    .catch((err) => showError("Error in fetching supplier data"));
}
