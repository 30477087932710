import React from "react";
import { Table as AntTable } from "antd";
import { Pagination } from "../pagination";
// import "antd/es/table/style/css";
// import "antd/es/pagination/style/css";
import styles from "./styles.module.css";
const { Column } = Table;
export function Table({ dataSource, columns, ...props }) {
  return <AntTable dataSource={dataSource} columns={columns} {...props} />;
}

export function EnegmaTable({ dataSource, columns, pagination, ...props }) {
  return (
    <div className={styles.enegmaTable}>
      {pagination ? (
        <>
          <AntTable
            dataSource={dataSource}
            columns={columns}
            pagination={<Pagination {...pagination} />}
            {...props}
          />
        </>
      ) : (
        <AntTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          {...props}
        />
      )}
    </div>
  );
}

export { Column };
