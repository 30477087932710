import { create } from "zustand";
import { getUser } from "../actions";
import { getMembers } from "../actions/user";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  userId: null,
  currentUser: null,
  members: [],
  membersTableConfig: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
};

const useUserStore = create((set, get) => ({
  ...store,
  setUserId: (id) => set(() => ({ userId: id })),
  setCurrentUser: (user) => set(() => ({ currentUser: user })),
  getCurrentUser: () => {
    const _currentUser = get().currentUser;
    if (!_currentUser) {
      getUser();
    }
  },
  getMembers: (pageNo = 1, pageSize = 100) => {
    const _members = get().members;
    const _tableConfigs = get().membersTableConfig;
    if (
      _members.length === 0 ||
      _tableConfigs.pagination?.current != pageNo ||
      _tableConfigs.pagination?.pageSize != pageSize
    ) {
      getMembers(pageNo, pageSize);
      get().setMembersTableConfig({ current: pageNo, pageSize: pageSize });
    }
  },
  setMembers: (members) => set(() => ({ members: members })),
  setMembersTableConfig: (config) => {
    set(() => ({
      membersTableConfig: {
        ...get().membersTableConfig,
        pagination: { ...get().membersTableConfig.pagination, ...config },
      },
    }));
  },
  reset: () => set({ ...store }),
}));

export { useUserStore };
