import { TABLES } from "../constants/tables";
import { UserService } from "../service";
import { useUserStore } from "../store";
import { getItem } from "../utils";
import { ALL_ROLES } from "../constants/roles";

export function getUser() {
  const setCurrentUser = useUserStore.getState().setCurrentUser;
  // const userId = useUserStore.getState().userId;
  const userId = getItem(TABLES.USER_ID);
  UserService.getUser(userId)
    .then((response) => {
      if (response) {
        setCurrentUser(response);
      }
    })
    .catch((err) => console.error(err.message));
}

export function getMembers(pageNo, pageSize) {
  const setMembers = useUserStore.getState().setMembers;
  const setMembersTableConfig = useUserStore.getState().setMembersTableConfig;
  UserService.getMembers(pageNo, pageSize).then((data) => {
    const members = data?.users?.map((user) => ({
      id: user.id,
      email: user?.email,
      name: user?.name,
      role: ALL_ROLES[user?.role]?.name,
    }));
    setMembers(members);
    setMembersTableConfig({ total: data.total_count });
  });
}
