import { post, get, put, deleteReq } from "./service";
import { getObject } from "../utils/storage";
import { TABLES } from "../constants/tables";

export const TeamService = {
  createTeam(data) {
    return post("/team/add-team", { body: data });
  },
  
  getTeams(pageNo, pageSize) {
    return get(`/team/get-teams?page_no=${pageNo}&page_size=${pageSize}`);
  },
  
  getTeam(team_id) {
    return get(`/team/get-team/${team_id}`);
  },
  
  deleteTeam(team_id) {
    return deleteReq(`/team/delete-team/${team_id}`, {}, true);
  },
  getActiveTeams(){
    return get(`/team/get-active-teams`);
  },
  updateTeam(team_id, data) {
    return put(`/team/update-team/${team_id}`, { body: data }, true);
  },
  
  deleteTeamUser(team_id, user_id) {
    return deleteReq(`/team/delete-team-user/${team_id}/${user_id}`, {}, true);
  },
  
  updateTeamUser(team_id, user_id, data) {
    return put(`/team/update-team-user/${team_id}/${user_id}`, { body: data }, true);
  },
  
  addTeamUser(team_id, data) {
    return post(`/team/add-team-user/${team_id}`, { body: data }, true);
  },
};
