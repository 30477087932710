import { create } from "zustand";
import { ItemService } from "../service";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  itemId: null,
  formSchema: null,
  formInstance: null,
  formData: null,
  manuallyAddingQuotes: false,
  showQuotationTable: false, // Disable Send RFQ / Manually add quotes btn if true
  releasingPO: null, // Show loader for action button in the selected row and disable other "Release PO" btn
};

const useFormStore = create((set, get) => ({
  ...store,
  setItemId: (id) => set(() => ({ itemId: id })),
  setFormInstance: (data) => set(() => ({ formInstance: data })),
  setFormSchema: (data) => set(() => ({ formSchema: data })),
  setFormData: (data) => set(() => ({ formData: data })),
  addRow: (connected_model_id) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
  
    ItemService.createLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id
    ).then((res) => {
      console.log(res);
  
      // Get the current rows from the form instance
      let _currentRows = formInstance.getFieldValue(connected_model_id) || [];
  
      // Add the new row
      const newRow = { id: res.id };
  
      // Update the form instance with the new row
      formInstance.setFieldValue(connected_model_id, [..._currentRows, newRow]);
  
      // Update formData in the store with the new row
      const updatedFormData = {
        ...get().formData,
        [connected_model_id]: [..._currentRows, newRow],
      };
      set(() => ({ formData: updatedFormData }));
    }).catch((error) => {
      console.error("Error adding row:", error);
    });
  },
  
  addBatchRows: (connected_model_id, batchData) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
  
    ItemService.createBatch(_schema.metadataId, _item_id, connected_model_id, batchData)
      .then((res) => {
        const currentRows = formInstance.getFieldValue(connected_model_id) || [];
        const newRows = Object.values(res).map((item) => ({
          id: item.id,
          item_name: item.item_name,
          item_code: item.item_code,
          item_desc: item.item_desc,
          item_quantity: item.item_quantity,
        }));
  
        // Remove duplicates before updating
        const uniqueNewRows = newRows.filter(newRow =>
          !currentRows.some(existingRow => existingRow.id === newRow.id)
        );
  
        // Update form instance
        formInstance.setFieldValue(connected_model_id, [...currentRows, ...uniqueNewRows]);
  
        // Update formData in the store (for keeping track in the state)
        const updatedFormData = {
          ...get().formData,
          [connected_model_id]: [...currentRows, ...uniqueNewRows]
        };
        set(() => ({ formData: updatedFormData }));
      })
      .catch((error) => {
        console.error("Error adding batch rows:", error);
      });
  },
  
  
  
  deleteRow: (connected_model_id, row_index) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let datatable = get().formData;
    const connected_model_row_id=datatable[connected_model_id][row_index].id
    ItemService.deleteLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id,
      connected_model_row_id
    ).then((res) => {
      console.log(res);
    });
  },
  setManuallyAddingQuotes: (manuallyAddingQuotes) => {
    set(() => ({ manuallyAddingQuotes: manuallyAddingQuotes }));
  },
  setShowQuotationTable: (showQuotationTable) => {
    set(() => ({ showQuotationTable: showQuotationTable }));
  },
  setReleasingPO: (releasingPO) => set(() => ({ releasingPO: releasingPO })),
  reset: () => set({ ...store }),
}));

export { useFormStore };
