import { create } from "zustand";
import { WorkflowService } from "../service";
import { showError } from "../widgets";
import { produce } from "immer";
import { generateId } from "../utils";
import { useAccountStore } from "./account.store";
import { getDraftFlowSchema } from "../actions/flow";

const store = {
  flowData: null,
  status: null,
  flowId: null,
};

const useFlowBuilderStore = create((set, get) => ({
  ...store,
  init: (flowId) => {
    useAccountStore.getState().setLoading(true);
    WorkflowService.getDraftMetadata(flowId)
      .then((data) => {
        const metadata = data;
        set(() => ({ flowData: metadata, status: "draft", flowId: flowId }));
      })
      .catch((err) => showError(err.message))
      .finally(() => useAccountStore.getState().setLoading(false));
  },
  getFlowData: (flowId) => {
    const _flowData = get().flowData;
    if (_flowData) {
      return _flowData;
    } else {
      getDraftFlowSchema(flowId);
    }
  },
  setFlowData: (flowData) => set(() => ({ flowData: flowData })),
  setFlowId: (flowId) => set(() => ({ flowId: flowId })),
  setStatus: (status) => set(() => ({ status: status })),
  addPhase: (phaseIndex) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        const {
          phase: phaseData,
          deadline: deadlineData,
          assignment: assignmentData,
        } = generatePhase();
        if (phaseIndex > -1) {
          _flowData.workflow.phases.splice(phaseIndex + 1, 0, phaseData.id);
        } else {
          _flowData.workflow.phases.push(phaseData.id);
        }
        _flowData[phaseData.id] = phaseData;
        _flowData[deadlineData.id] = deadlineData;
        _flowData[assignmentData.id] = assignmentData;
        state.flowData = _flowData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  removePhase: (phaseId) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        if (_flowData[phaseId].rows.length > 0) {
          _flowData[phaseId].rows.map((row) => {
            delete _flowData[_flowData[_flowData[row]["columns"][0]]["field"]];
            delete _flowData[_flowData[row]["columns"][0]];
            delete _flowData[row];
          });
        }
        delete _flowData[phaseId];

        const phaseIndex = _flowData.workflow.phases.findIndex(
          (phase) => phase === phaseId
        );
        _flowData.workflow.phases.splice(phaseIndex, 1);
        state.flowData = _flowData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  renamePhase: (phaseId, phaseName) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        _flowData[phaseId].name = phaseName;
        state.flowData = _flowData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  addAssignee: (phaseId, assignee) => {
    set(
      produce((state) => {
        const newAssignmentResourceId = "assignee"+generateId();
        const phaseData = state.flowData[phaseId];
  
        if (!phaseData.assignmentResources) {
          phaseData.assignmentResources = [];
        }

        const newAssignmentResource = {
          id: newAssignmentResourceId,
          name: newAssignmentResourceId,
          type: "assignmentResource",
          resourceType: "static",
          value: {
            id: assignee.id,
            type: assignee.type,
          },
        };
  
    
        state.flowData[newAssignmentResourceId] = newAssignmentResource;
    
        if (!phaseData.assignmentResources) {
          phaseData.assignmentResources = [];
        }
        phaseData.assignmentResources.push(newAssignmentResourceId);

        state.flowData[phaseId] = phaseData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  removeAssignee: (phaseId, assigneeId) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        const phaseData = _flowData[phaseId];
        const assignmentResources = phaseData.assignmentResources || [];

        const resourceIndex = assignmentResources.findIndex(
          (resourceId) => _flowData[resourceId].value.id === assigneeId
        );

        if (resourceIndex !== -1) {
          const [removedResourceId] = assignmentResources.splice(
            resourceIndex,
            1
          );

          delete _flowData[removedResourceId];
        }

        state.flowData[phaseId] = {
          ...phaseData,
          assignmentResources: assignmentResources,
        };

        state.flowData = _flowData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  addDeadline: (phaseId, deadline, unit) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        const phaseData = _flowData[phaseId];
        const SLAResources = phaseData.SLAResources || [];
  
        const newSLAResourceId = "SLA"+generateId();
        const newSLAResource = {
          id: newSLAResourceId,
          name: newSLAResourceId,
          type: "SLAResource",
          resourceType: "static",
          value: deadline,
          unit: unit
        };
  
        SLAResources.push(newSLAResourceId);
  
        _flowData[newSLAResourceId] = newSLAResource;
  
        state.flowData[phaseId] = {
          ...phaseData,
          SLAResources: SLAResources
        };
  
        state.flowData = _flowData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  getAssigneeAndDeadline: (phaseId) => {
    const _flowData = get().flowData;
    if (!_flowData) return {};

    const phaseData = _flowData[phaseId];
    if (!phaseData) return {};

    const assignees = (phaseData.assignmentResources || []).map(
      (resourceId) => _flowData[resourceId].value
    );

    const deadlines = (phaseData.SLAResources || []).map(
      (resourceId) => _flowData[resourceId].value
    );

    return { assignees, deadlines };
  }  ,
  saveDraft: () => {
    WorkflowService.saveDraftMetadata(get().flowId, get().flowData)
      .then(() => {
        getDraftFlowSchema(get().flowId);
      })
      .catch((err) => showError(err.message));
  },
  onPublish: () => {
    WorkflowService.saveLiveMetadata(
      useFlowBuilderStore.getState().flowId,
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({
          status: "live",
        }));
      })
      .catch((err) => showError(err.message));
  },
  setApproverPhase: (phaseId, isApproverPhase) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        _flowData[phaseId].needApproval = isApproverPhase;
        state.flowData = _flowData;
      })
    );
    useFlowBuilderStore.getState().saveDraft();
  },
  reset: () => set({ ...store }),
}));

function generatePhase() {
  const phaseId = "ph_" + generateId();
  const deadlineId = "duedate_" + generateId();
  const assignmentId = "assignment_" + generateId();
  const phaseObj = {
    id: phaseId,
    rows: [],
    duedate_rule: deadlineId,
    assignment: assignmentId,
    name: "Test",
  };
  const deadlineObj = {
    id: deadlineId,
    type: "duedate",
    duedatetype: "static",
  };
  const assignmentObj = {
    id: assignmentId,
    type: "Assignment",
    assignmenttype: "static",
    value: [],
  };

  return {
    phase: phaseObj,
    deadline: deadlineObj,
    assignment: assignmentObj,
  };
}

export { useFlowBuilderStore };
