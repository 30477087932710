import { create } from "zustand";
import { getFlowSchema } from "../actions/flow";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  flowData: null,
  positionData: null,
  schema: null,
  phases: [],
  itemsData: null,
  phasesData: null,
};

const useFlowStore = create((set, get) => ({
  ...store,
  setFlowData: (data) => set(() => ({ flowData: data })),
  setPositionData: (data) => set(() => ({ positionData: data })),
  setSchemaData: (data) => set(() => ({ schema: data })),
  getSchemaData: (id) => {
    const schema = get().schema;
    if (!schema) {
      getFlowSchema(id);
    }
  },
  setPhases: (data) => set(() => ({ phases: data })),
  setItemsData: (data) => set(() => ({ itemsData: data })),
  setPhasesData: (data) => set(() => ({ phasesData: data })),
  reset: () => set({ ...store }),
}));

export { useFlowStore };
