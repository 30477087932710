import {
  PurchaseOrderIcon,
  TravelRequestIcon,
  ExpenseRequestIcon,
  Icon,
  GroupIcon,
  SlackIcon,
} from "./shared/widgets";

const ALL_FLOWS = [
  {
    id: "purchase_order",
    title: "Purchase order",
    icon: <Icon src={PurchaseOrderIcon} />,
  },
  // {
  //   title: "Travel Request",
  //   id: "travel_request",
  //   icon: <Icon src={TravelRequestIcon} />,
  // },
  // {
  //   title: "Expense Request",
  //   id: "expense_request",
  //   icon: <Icon src={ExpenseRequestIcon} />,
  // },
];

const RECENT_FLOWS = [ALL_FLOWS[0], ALL_FLOWS[1]];

const MY_TASKS_DATA = [];

const NOTICICATION_DATA = [];

// permission: {
//   readonly: [],
//   hidden: []
// }

const WORKFLOW_ACTIONS = {
  form: {
    label: "Form",
    value: "Name",
    icon: <Icon size="2rem" src={GroupIcon} />,
  },
  notification: {
    label: "Notifications",
    value: "Slack",
    icon: <Icon size="2rem" src={SlackIcon} />,
  },
  assignment_options: {
    label: "Assignment Options",
    value: "Least Loaded",
    icon: <Icon size="2rem" src={GroupIcon} />,
  },
  deadline: {
    label: "Deadline",
    value: "20 Days",
    icon: <Icon src={GroupIcon} />,
  },
  assignee: {
    label: "Assignee(s)",
    value: "Add Assignees",
    icon: <Icon size="2rem" src={GroupIcon} />,
  },
  connected_flows: {
    label: "Connected flows",
    value: "RFX flow",
    icon: <Icon size="2rem" src={GroupIcon} />,
  },
};

const PURCHASE_ORDER_META_DATA = {
  id: "purchase_order",
  purchase_order: {
    title: "Purchase Request",
    desc: "",
    icon: "",
    workflow: [
      "workflow_Draft",
      "workflow_Approval",
      "workflow_RFQ",
      "workflow_Goods_Verification",
      "workflow_Waiting_Goods_Recipets",
      "workflow_Payment_Processing",
      "workflow_Complete",
    ],
    rows: [
      "row_Request_Id",
      "row_Request_Title",
      "row_requestor_name",
      "row_Department",
      "row_Delivery_Location",
      "row_ExpectedDeliveryDate",
      "row_Line_Item",
      "row_Purchase_Type",
      "row_Supplier_Selection",
      "row_Supplier_Line_Item",
      "row_TotalAmount",
      "row_SP_DeliveryDate",
      "row_Attachments",
    ],
    permission: {
      workflow_Draft: {
        row_Purchase_Type: "hidden",
        row_Supplier_Selection: "hidden",
        row_Supplier_Line_Item: "hidden",
        row_TotalAmount: "hidden",
        row_SP_DeliveryDate: "hidden",
        row_Attachments: "hidden",
      },
      workflow_Approval: {
        row_Purchase_Type: "hidden",
        row_Supplier_Selection: "hidden",
        row_Supplier_Line_Item: "hidden",
        row_TotalAmount: "hidden",
        row_SP_DeliveryDate: "hidden",
        row_Attachments: "hidden",
      },
    },
  },
  row_Request_Title: {
    id: "row_Request_Title",
    type: "field",
    field_id: "Request_Title",
  },
  row_Request_Id: {
    id: "row_Request_Id",
    type: "field",
    field_id: "Request_id",
  },
  row_requestor_name: {
    id: "row_requestor_name",
    type: "field",
    field_id: "Requestor_Name",
  },
  row_Department: {
    id: "row_Department",
    type: "field",
    field_id: "Department",
  },
  row_Line_Item: {
    id: "row_Line_Item",
    type: "table",
    table_id: "Line_Item",
  },
  row_Supplier_Selection: {
    id: "row_Supplier_Selection",
    type: "field",
    field_id: "Supplier_Selection",
  },
  row_Supplier_Line_Item: {
    id: "row_Supplier_Line_Item",
    type: "table",
    table_id: "Supplier_Line_Item",
  },
  row_Delivery_Location: {
    id: "row_Delivery_Location",
    type: "field",
    field_id: "Delivery_Location",
  },
  row_TotalAmount: {
    id: "row_TotalAmount",
    type: "field",
    field_id: "LI_Total_Amount",
  },
  row_Attachments: {
    id: "row_Attachments",
    type: "field",
    field_id: "Attachments",
  },
  row_SP_DeliveryDate: {
    id: "row_SP_DeliveryDate",
    type: "field",
    field_id: "DeliveryDate",
  },
  row_Purchase_Type: {
    id: "row_Purchase_Type",
    type: "field",
    field_id: "Purchase_Type",
  },
  row_ExpectedDeliveryDate: {
    id: "row_ExpectedDeliveryDate",
    type: "field",
    field_id: "ExpectedDeliveryDate",
  },
  LI_Total_Amount: {
    id: "LI_Total_Amount",
    row_id: "row_TotalAmount",
    type: "currency",
    label: "Total amount",
    isReadOnly: true,
  },
  Attachments: {
    id: "LI_Total_Amount",
    row_id: "row_Attachments",
    type: "attachment",
    label: "Attachments",
    isReadOnly: true,
  },
  DeliveryDate: {
    id: "DeliveryDate",
    row_id: "row_SP_DeliveryDate",
    type: "date",
    label: "Delivery Date",
    isReadOnly: true,
  },
  Request_id: {
    id: "Requestor_id",
    row_id: "row_Request_id",
    type: "text",
    label: "Purchase Order ID #",
    isReadOnly: true,
  },
  Requestor_Name: {
    id: "Requestor_Name",
    row_id: "row_requestor_name",
    type: "text",
    label: "Requestor Name",
    isReadOnly: true,
  },
  Department: {
    id: "Department",
    row_id: "row_Department",
    type: "dropdown",
    label: "Department",
    list: "DepartmentList",
  },

  Supplier_Selection: {
    id: "Supplier_Selection",
    row_id: "row_Supplier_Selection",
    type: "multiselect",
    label: "Choose from list of suppliers",
    list: "SupplierList",
  },

  ExpectedDeliveryDate: {
    id: "ExpectedDeliveryDate",
    row_id: "row_ExpectedDeliveryDate",
    type: "date",
    label: "Expected Delivery Date",
  },
  Purchase_Type: {
    id: "Purchase_Type",
    row_id: "row_Purchase_Type",
    type: "dropdown",
    label: "Purchasing Type",
    list: "IntakeList",
  },
  Line_Item: {
    id: "Line_Item",
    label: "Line Items",
    row_id: "row_Line_Item",
    fields: ["LI_Item_Name", "LI_Description", "LI_Qty"],
  },
  LI_Item_Name: {
    id: "LI_Item_Name",
    table_id: "Line_Item",
    type: "text",
    label: "Item Name",
  },
  LI_Description: {
    id: "LI_Description",
    table_id: "Line_Item",
    type: "textarea",
    label: "Product Description",
  },
  LI_Qty: {
    id: "LI_Qty",
    table_id: "Line_Item",
    type: "number",
    label: "Quantity",
  },
  Supplier_Line_Item: {
    id: "Supplier_Line_Item",
    row_id: "row_Supplier_Line_Item",
    label: "Supplier Line items",
    fields: [
      "Supplier_Name",
      "Supplier_Address",
      "Supplier_Phone_Number",
      "RFQ_Status",
      "SP_Delivery_Date",
      "SP_LI_Item_Name",
      "SP_LI_Item_Price",
      "SP_LI_Item_Qty",
      "SP_LI_Item_Total",
      "SP_LI_Total_Amount",
    ],
    isReadOnly: true,
  },
  Supplier_Name: {
    id: "Supplier_Name",
    table_id: "Supplier_Line_Item",
    type: "text",
    label: "Supplier Name",
  },
  Supplier_Address: {
    id: "Supplier_Address",
    table_id: "Supplier_Line_Item",
    type: "text",
    label: "Supplier Address",
  },
  Supplier_Phone_Number: {
    id: "Supplier_Phone_Number",
    table_id: "Supplier_Line_Item",
    type: "phonenumber",
    label: "Phone number",
  },
  SP_LI_Item_Qty: {
    id: "SP_LI_Item_Qty",
    table_id: "Supplier_Line_Item",
    type: "number",
    label: "Quantity",
  },
  SP_LI_Item_Name: {
    id: "SP_LI_Item_Name",
    table_id: "Supplier_Line_Item",
    type: "text",
    label: "Item Name",
  },
  RFQ_Status: {
    id: "RFQ_Status",
    table_id: "Supplier_Line_Item",
    type: "text",
    label: "RFQ Status",
  },
  SP_LI_Item_Price: {
    id: "SP_LI_Item_Price",
    table_id: "Supplier_Line_Item",
    type: "currency",
    label: "Item Price",
  },
  SP_Delivery_Date: {
    id: "SP_Delivery_Date",
    table_id: "Supplier_Line_Item",
    type: "date",
    label: "Delivery Date",
  },

  SP_LI_Item_Total: {
    id: "SP_LI_Item_Total",
    table_id: "Supplier_Line_Item",
    type: "currency",
    label: "Total",
  },
  SP_LI_Total_Amount: {
    id: "SP_LI_Total_Amount",
    table_id: "Supplier_Line_Item",
    type: "currency",
    label: "Total Amount",
  },
  Totalprice: {
    id: "Totalprice",
    row_id: "row_013",
    type: "currency",
    label: "Total price",
  },
  Delivery_Location: {
    id: "Delivery_Location",
    row_id: "row_007",
    type: "text",
    label: "Delivery Location",
  },
  Request_Title: {
    id: "Request_Title",
    row_id: "row_Request_Title",
    type: "text",
    label: "Request Title",
  },
  pr_initiation: {
    id: "pr_initiation",
    label: "Not started",
    assignee: [],
    sla: [],
  },
  workflow_Draft: {
    id: "workflow_Draft",
    label: "Requistion",
    assignee: [],
    sla: [],
    color: "gray",
    actions: ["form", "notification"],
  },
  workflow_Approval: {
    id: "workflow_Approval",
    label: "Approval",
    assignee: [],
    sla: [],
    color: "green",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_RFQ: {
    id: "workflow_RFQ",
    label: "Procurement",
    assignee: [],
    sla: [],
    color: "orange",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_Supplier_Selection: {
    id: "workflow_Supplier_Selection",
    label: "Supplier Selection",
    assignee: [],
    sla: [],
    color: "indigo",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_Waiting_Goods_Recipets: {
    id: "workflow_Waiting_Goods_Receipts",
    label: "Reconciliation",
    assignee: [],
    sla: [],
    color: "yellow",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_Goods_Verification: {
    id: "workflow_Goods_Verification",
    label: "Delivery",
    assignee: [],
    sla: [],
    color: "pink",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_Invoice_Processing: {
    id: "workflow_Invoice_Processing",
    label: "Invoice Processing",
    assignee: [],
    sla: [],
    color: "purple",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_Payment_Processing: {
    id: "workflow_Payment_Processing ",
    label: "Payment Processing",
    assignee: [],
    sla: [],
    color: "rose",
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  workflow_Complete: {
    id: "workflow_Complete ",
    label: "Payment Complete",
    assignee: [],
    sla: [],
    color: "blue",
    actions: ["notification", "connected_flows"],
  },
};

const TRAVEL_REQUEST_META_DATA = {
  id: "travel_request",
  travel_request: {
    title: "Travel Request",
    desc: "",
    icon: "",
    workflow: [
      "Workflow_Initial_Draft",
      "Workflow_Manager_Review",
      "Workflow_Finance_Approval",
      "Workflow_Payment_Processing",
      "Workflow_Done",
    ],
    rows: [
      "row_Request_Id",
      "row_Requestor_Name",
      "row_Department",
      "row_Reporting_Manager",
      "row_Purpose_of_travel",
      "row_From_Date",
      "row_To_Date",
      "row_Attachments",
      "row_Total_Days_of_travel",
      "row_Travel_Line_Item",
      "row_Total_Travel_Amount",
      "row_Food_Expense_Line_Item",
      "row_Total_Food_Amount",
      "row_Total_Amount",
      "row_Approved_Budget",
    ],
  },
  row_Request_Id: {
    id: "row_Request_Id",
    type: "field",
    field_id: "Request_id",
  },
  row_Requestor_Name: {
    id: "row_Requestor_Name",
    type: "field",
    field_id: "Requestor_Name",
  },
  row_Department: {
    id: "row_Department",
    type: "field",
    field_id: "Department",
  },
  row_Reporting_Manager: {
    id: "row_Reporting_Manager",
    type: "field",
    field_id: "Reporting_Manager",
  },
  row_Purpose_of_travel: {
    id: "row_Purpose_of_travel",
    type: "field",
    field_id: "Purpose_of_travel",
  },
  row_From_Date: {
    id: "row_From_Date",
    type: "field",
    field_id: "From_Date",
  },
  row_To_Date: {
    id: "row_To_Date",
    type: "field",
    field_id: "To_Date",
  },
  row_Total_Days_of_travel: {
    id: "row_Total_Days_of_travel",
    type: "field",
    field_id: "Total_Days_of_travel",
  },
  row_Attachments: {
    id: "row_Attachments",
    type: "field",
    field_id: "Attachments",
  },
  row_Travel_Line_Item: {
    id: "row_Travel_Line_Item",
    type: "table",
    table_id: "Travel_Line_Item",
  },
  row_Total_Travel_Amount: {
    id: "row_Total_Travel_Amount",
    type: "field",
    field_id: "Total_Travel_Amount",
  },
  row_Food_Expense_Line_Item: {
    id: "row_Food_Expense_Line_Item",
    type: "table",
    table_id: "Food_Expense_Line_Item",
  },
  row_Total_Food_Amount: {
    id: "row_Total_Food_Amountt",
    type: "field",
    field_id: "Total_Food_Amount",
  },
  row_Total_Amount: {
    id: "row_Total_Amount",
    type: "field",
    field_id: "Total_Amount",
  },
  row_Approved_Budget: {
    id: "row_Approved_Budget",
    type: "field",
    field_id: "Approved_Budget",
  },
  Requestor_Name: {
    id: "Requestor_Name",
    row_id: "row_Requestor_Name",
    type: "text",
    label: "Requestor Name",
    isRequired: true,
  },
  Department: {
    id: "Department",
    row_id: "row_Department",
    type: "dropdown",
    label: "Department",
    list: "DepartmentList",
  },
  Request_id: {
    id: "Requestor_id",
    row_id: "row_Request_id",
    type: "text",
    label: "Travel Request ID #",
  },
  Reporting_Manager: {
    id: "Reporting_Manager",
    row_id: "row_Reporting_Manager",
    type: "dropdown",
    label: "Reporting Manager",
    list: "DepartmentList",
  },
  From_Date: {
    id: "From_Date",
    row_id: "row_From_Date",
    type: "date",
    label: "From Date",
  },
  To_Date: {
    id: "To_Date",
    row_id: "row_To_Date",
    type: "date",
    label: "To Date",
  },
  Total_Days_of_travel: {
    id: "Total_Days_of_travel",
    row_id: "row_Total_Days_of_travel",
    type: "number",
    label: "Total Days of Travel",
  },
  Purpose_of_travel: {
    id: "Purpose_of_travel",
    row_id: "row_Purpose_of_travel",
    type: "text",
    label: "Purpose of travel",
  },
  Attachments: {
    id: "Attachments",
    row_id: "row_Attachments",
    type: "attachment",
    label: "Bill Attachments",
  },

  Travel_Line_Item: {
    id: "Travel_Line_Item",
    row_id: "row_Travel_Line_Item",
    fields: [
      "TL_Bill_Id",
      "TL_Bill_Date",
      "TL_Vendor_Name",
      "TL_Travel_Date",
      "TL_From",
      "TL_To",
      "TL_Mode_of_transport",
      "TL_BillAmount",
    ],
  },
  TL_Bill_Id: {
    id: "TL_Bill_Id",
    table_id: "Travel_Line_Item",
    type: "text",
    label: "Bill Id",
  },
  TL_Bill_Date: {
    id: "TL_Bill_Date",
    table_id: "Travel_Line_Item",
    type: "date",
    label: "Bill Date",
  },
  TL_Travel_Date: {
    id: "TL_Travel_Date",
    table_id: "Travel_Line_Item",
    type: "date",
    label: "Travel Date",
  },
  TL_From: {
    id: "TL_From",
    table_id: "Travel_Line_Item",
    type: "text",
    label: "From",
  },
  TL_To: {
    id: "TL_To",
    table_id: "Travel_Line_Item",
    type: "text",
    label: "TO",
  },
  TL_Mode_of_transport: {
    id: "TL_Mode_of_transport",
    table_id: "Travel_Line_Item",
    type: "text",
    label: "Mode of Transport",
  },
  TL_Vendor_Name: {
    id: "TL_Vendor_Name",
    table_id: "Travel_Line_Item",
    type: "text",
    label: "Vendor Name",
  },
  TL_BillAmount: {
    id: "TL_BillAmount",
    table_id: "Travel_Line_Item",
    type: "currency",
    label: "Bill Amount",
  },
  Total_Travel_Amount: {
    id: "Total_Travel_Amount",
    row_id: "row_Total_Travel_Amount",
    type: "currency",
    label: "Total Amount spent in Travel",
  },
  Total_Food_Amount: {
    id: "Total_Food_Amount",
    row_id: "row_Total_Food_Amount",
    type: "currency",
    label: "Total Amount spent in Food and Entertainment",
  },
  Approved_Budget: {
    id: "Approved_Budget",
    row_id: "row_Approved_Budget",
    type: "currency",
    label: "Budget Approved",
  },
  Food_Expense_Line_Item: {
    id: "Food_Expense_Line_Item",
    row_id: "row_Food_Expense_Line_Item",
    fields: ["FE_Bill_Id", "FE_Bill_Date", "FE_Vendor_Name", "FE_Bill_Amount"],
  },
  FE_Bill_Id: {
    id: "FE_Bill_Id",
    table_id: "Food_Expense_Line_Item",
    type: "text",
    label: "Bill Id",
  },
  TL_Bill_Date: {
    id: "TL_Bill_Date",
    table_id: "Food_Expense_Line_Item",
    type: "date",
    label: "Bill Date",
  },
  FE_Vendor_Name: {
    id: "FE_Vendor_Name",
    table_id: "Food_Expense_Line_Item",
    type: "text",
    label: "Vendor Name",
  },
  FE_Bill_Amount: {
    id: "FE_Bill_Amount",
    table_id: "Food_Expense_Line_Item",
    type: "currency",
    label: "Bill Amount",
  },
  Workflow_Initial_Draft: {
    id: "Workflow_Initial_Draft",
    label: "Initial Draft",
    assignee: [],
    sla: [],
    actions: ["form", "notification"],
  },
  Workflow_Manager_Review: {
    id: "Workflow_Manager_Review",
    label: "Manager Review",
    assignee: [],
    sla: [],
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  Workflow_Finance_Approval: {
    id: "Workflow_Payment_Processing",
    label: "Finance Approval",
    assignee: [],
    sla: [],
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  Workflow_Payment_Processing: {
    id: "Workflow_Payment_Processing",
    label: "Payment Processing",
    assignee: [],
    sla: [],
    actions: [
      "assignee",
      "assignment_options",
      "deadline",
      "notification",
      "connected_flows",
    ],
  },
  Workflow_Done: {
    id: "Workflow_Done",
    label: "Completed",
    assignee: [],
    sla: [],
    actions: ["notification", "connected_flows"],
  },
};

const EXPENSE_REQUEST_META_DATA = {
  id: "expense_request",
  expense_request: {
    title: "Expense Request",
    desc: "",
    icon: "",
    workflow: [
      "Workflow_Initial_Draft",
      "Workflow_Manager_Review",
      "Workflow_Finance_Approval",
      "Workflow_Payment_Processing",
      "Workflow_Done",
    ],
    rows: [
      "row_Request_Id",
      "row_Requested_Date",
      "row_Requestor_Name",
      "row_Department",
      "row_Reporting_Manager",
      "row_Attachments",
      "row_Expense_Line_Item",
      "row_Total_Expenses",
    ],
  },
  row_Request_Id: {
    id: "row_Request_Id",
    type: "field",
    field_id: "Request_id",
  },
  row_Requestor_Name: {
    id: "row_Requestor_Name",
    type: "field",
    field_id: "Requestor_Name",
  },
  row_Department: {
    id: "row_Department",
    type: "field",
    field_id: "Department",
  },
  row_Reporting_Manager: {
    id: "row_Reporting_Manager",
    type: "field",
    field_id: "Reporting_Manager",
  },
  row_Requested_Date: {
    id: "row_Requested_Date",
    type: "field",
    field_id: "Requested_Date",
  },
  row_Expense_Line_Item: {
    id: "row_Expense_Line_Item",
    type: "table",
    table_id: "Expense_Line_Item",
  },
  row_Total_Expenses: {
    id: "row_Total_Expenses",
    type: "field",
    field_id: "Total_Expenses",
  },
  Request_id: {
    id: "Requestor_id",
    row_id: "row_Request_id",
    type: "text",
    label: "Request ID #",
  },
  row_Attachments: {
    id: "row_Attachments",
    type: "field",
    field_id: "Attachments",
  },
  Requestor_Name: {
    id: "Requestor_Name",
    row_id: "row_Requestor_Name",
    type: "text",
    label: "Requestor Name",
    isRequired: true,
  },
  Department: {
    id: "Department",
    row_id: "row_Department",
    type: "dropdown",
    label: "Department",
    list: "DepartmentList",
  },
  Reporting_Manager: {
    id: "Reporting_Manager",
    row_id: "row_Reporting_Manager",
    type: "dropdown",
    label: "Reporting Manager",
    list: "DepartmentList",
  },
  Requested_Date: {
    id: "Requested_Date",
    row_id: "row_Requested_Date",
    type: "date",
    label: "Requested Date",
  },

  Attachments: {
    id: "Attachments",
    row_id: "row_Attachments",
    type: "attachment",
    label: "Smart Bill Attachments",
  },

  Expense_Line_Item: {
    id: "Expense_Line_Item",
    row_id: "row_Expense_Line_Item",
    fields: [
      "E_Bill_Id",
      "E_Bill_Date",
      "E_Expense_Category",
      "E_Vendor_Name",
      "E_Bill_Amount",
    ],
  },
  E_Bill_Id: {
    id: "E_Bill_Id",
    table_id: "Expense_Line_Item",
    type: "text",
    label: "Bill Id",
  },
  E_Bill_Date: {
    id: "E_Bill_Date",
    table_id: "Expense_Line_Item",
    type: "date",
    label: "Bill Date",
  },
  E_Vendor_Name: {
    id: "E_Vendor_Name",
    table_id: "Expense_Line_Item",
    type: "text",
    label: "Vendor Name",
  },
  E_Expense_Category: {
    id: "E_Expense_Category",
    table_id: "Expense_Line_Item",
    type: "text",
    label: "Expense Category",
  },
  E_Bill_Amount: {
    id: "E_Bill_Amount",
    table_id: "Expense_Line_Item",
    type: "currency",
    label: "Bill Amount",
  },
  Total_Expenses: {
    id: "Total_Expenses",
    row_id: "row_Total_Expenses",
    type: "currency",
    label: "Total Amount spent",
  },
  Workflow_Initial_Draft: {
    id: "Workflow_Initial_Draft",
    label: "Initial Draft",
    assignee: [],
    sla: [],
  },
  Workflow_Manager_Review: {
    id: "Workflow_Manager_Review",
    label: "Manager Review",
    assignee: [],
    sla: [],
  },
  Workflow_Finance_Approval: {
    id: "Workflow_Payment_Processing",
    label: "Finance Approval",
    assignee: [],
    sla: [],
  },
  Workflow_Payment_Processing: {
    id: "Workflow_Payment_Processing",
    label: "Payment Processing",
    assignee: [],
    sla: [],
  },
  Workflow_Done: {
    id: "Workflow_Done",
    label: "Completed",
    assignee: [],
    sla: [],
  },
};

const SUPPLIER_FORM = {
  id: "supplier_form",
  supplier_form: {
    title: "Supplier Form",
    desc: "",
    icon: "",
    rows: [
      "row_Request_Id",
      "row_Delivery_Location",
      "row_ExpectedDeliveryDate",
      "row_Supplier_Name",
      "row_Supplier_Address",
      "row_Supplier_Phone_Number",
      "row_Supplier_DeliveryDate",
      "row_Supplier_Line_Item",
    ],
  },
  row_Request_Id: {
    id: "row_Request_Id",
    type: "field",
    field_id: "Request_id",
  },
  row_Delivery_Location: {
    id: "row_Delivery_Location",
    type: "field",
    field_id: "Delivery_Location",
  },
  row_ExpectedDeliveryDate: {
    id: "row_ExpectedDeliveryDate",
    type: "field",
    field_id: "ExpectedDeliveryDate",
  },
  row_Supplier_Name: {
    id: "row_Supplier_Name",
    type: "field",
    field_id: "Supplier_Name",
  },
  row_Supplier_Address: {
    id: "row_Supplier_Address",
    type: "field",
    field_id: "Supplier_Address",
  },
  row_Supplier_Phone_Number: {
    id: "row_Supplier_Phone_Number",
    type: "field",
    field_id: "Supplier_Phone_Number",
  },
  row_Supplier_DeliveryDate: {
    id: "row_Supplier_DeliveryDate",
    type: "field",
    field_id: "Supplier_DeliveryDate",
  },
  row_Supplier_Line_Item: {
    id: "row_Supplier_Line_Item",
    type: "table",
    table_id: "Supplier_Line_Item",
  },
  Request_id: {
    id: "Requestor_id",
    row_id: "row_Request_id",
    type: "text",
    label: "Purchase Order ID #",
    isReadOnly: true,
  },
  Delivery_Location: {
    id: "Delivery_Location",
    row_id: "row_007",
    type: "text",
    label: "Delivery Location",
    isReadOnly: true,
  },
  ExpectedDeliveryDate: {
    id: "ExpectedDeliveryDate",
    row_id: "row_ExpectedDeliveryDate",
    type: "date",
    label: "Expected Delivery Date",
    isReadOnly: true,
  },
  Supplier_Name: {
    id: "Supplier_Name",
    row_id: "row_Supplier_Name",
    type: "text",
    label: "Supplier Name",
    isReadOnly: true,
  },
  Supplier_Address: {
    id: "Supplier_Address",
    row_id: "row_Supplier_Address",
    type: "text",
    label: "Supplier Address",
    isReadOnly: true,
  },
  Supplier_Phone_Number: {
    id: "Supplier_Phone_Number",
    row_id: "row_Supplier_Phone_Number",
    type: "phonenumber",
    label: "Phone number",
    isReadOnly: true,
  },
  Supplier_DeliveryDate: {
    id: "Supplier_DeliveryDate",
    row_id: "row_Supplier_DeliveryDate",
    type: "date",
    label: "Supplier Delivery date",
  },
  Supplier_Line_Item: {
    id: "Supplier_Line_Item",
    row_id: "row_Supplier_Line_Item",
    label: "Supplier Line items",
    fields: [
      "SP_LI_Item_Name",
      "SP_LI_Item_Qty",
      "SP_LI_Item_Price",
      "SP_LI_Item_Total",
      "SP_LI_Total_Amount",
    ],
    isReadOnly: true,
  },
  SP_LI_Item_Name: {
    id: "SP_LI_Item_Name",
    table_id: "Supplier_Line_Item",
    type: "text",
    label: "Item Name",
    isReadOnly: true,
  },
  SP_LI_Item_Qty: {
    id: "SP_LI_Item_Qty",
    table_id: "Supplier_Line_Item",
    type: "number",
    label: "Quantity",
    isReadOnly: true,
  },
  SP_LI_Item_Price: {
    id: "SP_LI_Item_Price",
    table_id: "Supplier_Line_Item",
    type: "currency",
    label: "Item Price",
  },
  SP_LI_Item_Total: {
    id: "SP_LI_Item_Total",
    table_id: "Supplier_Line_Item",
    type: "currency",
    label: "Total",
    isReadOnly: true,
  },
  SP_LI_Total_Amount: {
    id: "SP_LI_Total_Amount",
    table_id: "Supplier_Line_Item",
    type: "currency",
    label: "Total Amount",
    isReadOnly: true,
  },
};

const FLOW_META_DATA = {
  expense_request: EXPENSE_REQUEST_META_DATA,
  purchase_order: PURCHASE_ORDER_META_DATA,
  travel_request: TRAVEL_REQUEST_META_DATA,
  supplier_form: SUPPLIER_FORM,
};

const FLOW_REQUEST_DATA = {
  purchase_order: {
    position: [],
    items: [],
  },
  travel_request: {
    position: [],
    items: [],
  },
  expense_request: {
    position: ["item_1", "item_2", "item_3"],
    items: [
      {
        id: "item_1",
        title: "Title 1q",
        desc: "Mock description",
        step: "Workflow_Initial_Draft",
      },
      {
        id: "item_2",
        title: "Title 2",
        desc: "Mock description",
        step: "Workflow_Initial_Draft",
      },
      {
        id: "item_3",
        title: "Title 3",
        desc: "Mock description",
        step: "Workflow_Manager_Review",
      },
    ],
  },
};

// V2 Form Builder Meta data when all phases form are included and configured
export const PURCHASE_ORDER_META_DATA_V2 = {
  Metadata_Id: "purchase_order",
  Table_Id: "PurchaseOrder",
  Name: "Purchase Order",
  Workflow: {
    Id: "workflow_purchase_Order",
    Name: "Purchase Order Workflow",
    Phases: ["Phase_Draft", "Phase_Approval", "Phase_RFX"],
  },
  Phase_Draft: {
    Id: "Phase_Draft",
    Name: "Requistion",
    Rows: ["Row_5NJYVjotfs"],
    Type: "Phase",
  },
  Row_5NJYVjotfs: {
    Phase_Id: "Phase_Draft",
    Rows: ["Row_j32o5NJYVj", "Row_j42o5NJYVj", "Row_SGXvCUotfs"],
  },
  Row_j32o5NJYVj: {
    Id: "Row_j32o5NJYVj",
    Parent_Row: "Row_5NJYVjotfs",
    Columns: ["Col_j32o5NJYVj"],
  },
  Col_j32o5NJYVj: {
    Id: "Col_j32o5NJYVj",
    Row_Id: "Row_j32o5NJYVj",
    Type: "Field",
    Field_Id: "Field_ms6kIgQV6J",
  },
  Field_ms6kIgQV6J: {
    Id: "Field_ms6kIgQV6J",
    Col_Id: "Col_j32o5NJYVj",
    Type: "Text",
    Label: "Purchase Order ID",
    Is_Required: true,
    System_Field: true,
  },
  Row_j42o5NJYVj: {
    Id: "Row_j42o5NJYVj",
    Parent_Row: "Row_5NJYVjotfs",
    Columns: ["Col_j42o5NJYVj"],
  },
  Col_j42o5NJYVj: {
    Id: "Col_j42o5NJYVj",
    Row_Id: "Row_j42o5NJYVj",
    Type: "Field",
    Field_Id: "Field_ms0kIgQV6J",
  },
  Field_ms0kIgQV6J: {
    Id: "Field_ms0kIgQV6J",
    Col_Id: "Col_j42o5NJYVj",
    Type: "Text",
    Label: "Short Text",
  },
  Row_SGXvCUotfs: {
    Id: "Row_SGXvCUotfs",
    Parent_Row: "Row_5NJYVjotfs",
    Columns: ["Col_SGXvCUotfs"],
  },
  Col_SGXvCUotfs: {
    Id: "Col_SGXvCUotfs",
    Row_Id: "Row_SGXvCUotfs",
    Type: "field",
    Field_Id: "Field_tyFQetB4lo",
  },
  Field_tyFQetB4lo: {
    Id: "Field_tyFQetB4lo",
    Col_Id: "Col_SGXvCUotfs",
    Type: "Text",
    Label: "Short Text",
  },
  Phase_Approval: {
    Id: "Phase_Approval",
    Name: "Approval",
    Rows: ["Row_8NJYVjotfs"],
    Deadline: "Deadline_01",
    Assignment: "Assignment001",
  },
  Deadline_01: {
    Id: "Deadline_01",
    Type: "Deadline",
    PhaseId: "Phase_Approval",
    SLAType: "Static",
    Value: "8",
    Unit: "days",
  },
  Assignment001: {
    Id: "Assignment001",
    Type: "Assignment",
    PhaseId: "Phase_Approval",
    AssignmentType: "Static",
    Value: ["User001", "User002"],
  },
  Row_8NJYVjotfs: {
    Phase_Id: "Phase_Approval",
    Rows: ["Row_j51o5NJYVj", "Row_SGYwCUotfs"],
  },
  Row_j51o5NJYVj: {
    Id: "Row_j51o5NJYVj",
    Parent_Row: "Row_8NJYVjotfs",
    Columns: ["Col_j51o5NJYVj"],
  },
  Col_j51o5NJYVj: {
    Id: "Col_j51o5NJYVj",
    Row_Id: "Row_j51o5NJYVj",
    Type: "field",
    Field_Id: "Field_ms2kIgQV6J",
  },
  Field_ms2kIgQV6J: {
    Id: "Field_ms2kIgQV6J",
    Col_Id: "Col_j51o5NJYVj",
    Type: "Text",
    Label: "Short Text",
  },
  Row_SGYwCUotfs: {
    Id: "Row_SGYwCUotfs",
    Parent_Row: "Row_8NJYVjotfs",
    Columns: ["Col_SGYwCUotfs"],
  },
  Col_SGYwCUotfs: {
    Id: "Col_SGYwCUotfs",
    Row_Id: "Row_SGYwCUotfs",
    Type: "Field",
    Field_Id: "Field_tzFQetB4lo",
  },
  Field_tzFQetB4lo: {
    Id: "Field_tzFQetB4lo",
    Col_Id: "Col_SGYwCUotfs",
    Type: "Text",
    Label: "Short Text",
  },
  Phase_RFX: {
    Id: "Phase_RFX",
    Name: "RFX",
    Rows: ["Row_0NJYVjotfs"],
    Deadline: "Deadline_02",
    Assignment: "Assignment002",
  },
  Row_0NJYVjotfs: {
    Phase_Id: "Phase_Approval",
    Rows: [],
  },
  Deadline_02: {
    Id: "Deadline_02",
    Type: "Deadline",
    PhaseId: "Phase_RFX",
    SLAType: "Static",
  },
  Assignment002: {
    Id: "Assignment002",
    Type: "Assignment",
    PhaseId: "Phase_RFX",
    AssignmentType: "Static",
    Value: [],
  },
};

const PurchaseOrderSchema = {
  MetadataId: "Purchase_Order",
  Name: "Purchase Order",
  TableId: "PurchaseOrder",
  Fields: [
    "Id",
    "Requested_By",
    "Request_Title",
    "Request_Id",
    "Requestor_Name",
  ],
  _Prefix: "PO",
  Workflow: {
    Id: "Purchase_Order",
    Name: "Purchase Order",
    Phases: ["Phases_Requisition", "Phases_Approval"],
  },
  Phases_Requisition: {
    Id: "Phases_Requisition",
    Name: "Requisition",
    Category: "Draft",
    Type: "Phase",
    Row: ["Row_Phase_Requisition"],
  },
  Phases_Approval: {
    Id: "Phases_Approval",
    Name: "Approval",
    Category: "In Progress",
    Type: "Phase",
    Row: ["Row_Phases_Approval"],
  },
  Row_Phase_Requisition: {
    Id: "Row_Phase_Requisition",
    Type: "Row",
    Rows: ["Row_Phase_Requisition_1", "Row_Phase_Requisition_2"],
  },
  Row_Phase_Requisition_1: {
    Id: "Row_Phase_Requisition_1",
    Type: "Row",
    Columns: ["Request_Title"],
  },
  Row_Phase_Requisition_2: {
    Id: "Row_Phase_Requisition_2",
    Type: "Row",
    Columns: ["Request_Id"],
  },
  Row_Phases_Approval: {
    Id: "Row_Phases_Approval",
    Type: "Row",
    Rows: ["Row_Phase_Approval_1", "Row_Phase_Approval_2"],
  },
  Row_Phase_Approval_1: {
    Id: "Row_Phase_Approval_1",
    Type: "Row",
    Columns: ["C_LINE_ITEM"],
  },
  Row_Phase_Approval_2: {
    Id: "Row_Phase_Approval_2",
    Type: "Row",
    Columns: ["Purchase_Type"],
  },
  C_LINE_ITEM: {
    Id: "C_LINE_ITEM",
    Label: "LINE ITEM",
    TableId: "Line_Items",
  },
  Column_1: {
    Id: "Column_1",
    FieldId: "Request_Title",
  },
  Column_2: {
    Id: "Column_1",
    FieldId: "Request_Title",
  },

  Id: {
    Id: "Id",
    Label: "Id",
    DBType: "Text",
    FieldType: "Text",
    Type: "Field",
    IsPrimaryKey: true,
  },
  Request_Title: {
    Id: "Request_Title",
    Label: "Request Title",
    DBType: "Text",
    FieldType: "Text",
    Type: "Field",
  },
  Request_Id: {
    Id: "Request_Id",
    Label: "Request Id",
    DBType: "Text",
    FieldType: "Text",
    Type: "Field",
  },
  // _created_by: {
  //   Id: "_created_by",
  //   Label: "Created By",
  //   DBType: "Text",
  //   FieldType: "MultiUser",
  // },
  _created_at: {
    Id: "_created_at",
    Label: "Created At",
    DBType: "DateTime",
    FieldType: "DateTime",
  },
  _modified_by: {
    Id: "_modified_by",
    Label: "Modified By",
    DBType: "Text",
    FieldType: "User",
  },
  _modified_at: {
    Id: "_modified_at",
    Label: "Modified At",
    DBType: "DateTime",
    FieldType: "DateTime",
  },
  Requested_By: {
    Id: "Requested_By",
    Label: "Requested By",
    DBType: "Text",
    FieldType: "User",
    Type: "Field",
  },
  Requestor_Id: {
    Id: "Requestor_Id",
    Label: "Requestor Id",
    DBType: "Text",
    FieldType: "Text",
    Type: "Field",
  },
  Requestor_Name: {
    Id: "Requestor_Name",
    Label: "Requestor Name",
    DBType: "Text",
    FieldType: "Text",
    Type: "Field",
  },
  Requested_Date: {
    Id: "Requested_Date",
    Label: "Requested Date",
    DBType: "Date",
    FieldType: "Date",
  },
  Delivery_Location: {
    Id: "Delivery_Location",
    Label: "Delivery Location",
    DBType: "Text",
    FieldType: "Text",
  },
  Department: {
    Id: "Department",
    Label: "Department",
    DBType: "Text",
    FieldType: "Text",
  },
  Expected_Delivery_Date: {
    Id: "Expected_Delivery_Date",
    Label: "Expected Delivery Date",
    DBType: "Date",
    FieldType: "Date",
  },
  Purchase_Type: {
    Id: "Purchase_Type",
    Label: "Purchase Type",
    DBType: "Text",
    FieldType: "select",
  },
  Supplier_Selection: {
    Id: "Supplier_Selection",
    Label: "Choose your suppliers",
    DBType: "Text",
    FieldType: "Text",
  },
  Delivery_Date: {
    Id: "Delivery_Date",
    Label: "Delivery Date",
    DBType: "Date",
    FieldType: "Date",
  },
  // Assignee: {
  //   Id: "Assignee",
  //   Label: "Assignee",
  //   DBType: "json",
  //   FieldType: "MultiUser",
  // },
  // Priority: {
  //   Id: "Priority",
  //   Label: "Priority",
  //   DBType: "text",
  //   FieldType: "Dropdown",
  // },
  Price: {
    Id: "Price",
    Label: "Price",
    DBType: "Integer",
    FieldType: "Currency",
  },
  DueDate: {
    Id: "DueDate",
    Label: "Due date",
    DBType: "DateTime",
    FieldType: "DateTime",
  },
  PO_Name: {
    Id: "PO_Name",
    Label: "PO Name",
    DBType: "text",
    FieldType: "SequenceId",
    Prefix: "PO",
    SequenceTableId: "purchase_order_counter_seq",
  },
  // Attachments: {
  //   Id: "Attachments",
  //   Label: "Attachments",
  //   DBType: "json",
  //   FieldType: "Attachment",
  //   SequenceTableId: "purchase_order_counter_seq",
  // },
  ConnectedModels: ["Line_Items", "Supplier_Quotations"],
  Line_Items: {
    Name: "Line Item",
    TableId: "Line_Item",
    Type: "ConnectedModel",
    MetadataId: "Line_Item",
    _Prefix: "LI",
    Fields: ["PO_Id", "Item_Name", "Item_Total", "Item_Price", "Item_Qty"],
  },
  PO_Id: {
    Id: "PO_Id",
    Label: "PO_Id",
    DBType: "Text",
    FieldType: "Text",
    IsPrimaryKey: true,
  },
  Item_Name: {
    Id: "Item_Name",
    Label: "Item Name",
    DBType: "Text",
    FieldType: "Text",
  },
  Item_Total: {
    Id: "Item_Total",
    Label: "Item Total Title",
    DBType: "json",
    FieldType: "Currency",
  },
  Item_Price: {
    Id: "Item_Price",
    Label: "Item Price",
    DBType: "Json",
    FieldType: "Currency",
  },
  Item_Qty: {
    Id: "Item_Qty",
    Label: "Item Quantity",
    DBType: "Number",
    FieldType: "Number",
  },
};

const PurchaseOrderData = {
  Id: "req_001",
  _created_by: "user_01",
  _created_at: "2024-08-25T10:15:00Z",
  _modified_by: "user_01",
  _modified_at: "2024-08-25T10:30:00Z",
  Requested_By: "user_02",
  Request_Title: "Purchase New Laptops",
  Request_Id: "REQ-2024-0001",
  Requestor_Name: "Alice Johnson",
  Requested_Date: "2024-08-20",
  Delivery_Location: "Main Office",
  Department: "IT",
  Expected_Delivery_Date: "2024-09-15",
  Purchase_Type: "Amazon Punchout",
  Supplier_Selection: "TechWorld",
  Delivery_Date: "2024-09-10",
  Assignee: "John Doe",
  Priority: "High",
  Price: 15000,
  DueDate: "2024-09-01",
  PO_Name: "PO-2024-001",
  // Attachments: [
  //   {
  //     File_Name: "laptop_specs.pdf",
  //     File_Size: "2MB",
  //   },
  //   {
  //     File_Name: "quote_techworld.pdf",
  //     File_Size: "1MB",
  //   },
  // ],
};

const S3_BUCKET_URL = "https://enegmaattachments.s3.ap-south-1.amazonaws.com/";

export {
  ALL_FLOWS,
  RECENT_FLOWS,
  MY_TASKS_DATA,
  NOTICICATION_DATA,
  FLOW_META_DATA,
  FLOW_REQUEST_DATA,
  WORKFLOW_ACTIONS,
  PurchaseOrderSchema,
  PurchaseOrderData,
  S3_BUCKET_URL,
};
