import { Component } from "react";
import Loadable from "react-loadable";

import PropTypes from "prop-types";

export class LazyLoad extends Component {
  constructor(props) {
    super(props);
    let component = this.props.component;
    let componentName = this.props.componentName;
    this.LoadableComponent = Loadable({
      loader: component,
      render(loaded, props) {
        let Component = loaded[componentName] || "default";
        return <Component {...props} />;
      },
      loading: Loading,
      delay: 300,
    });
  }
  render() {
    const LoadableComponent = this.LoadableComponent;
    return <LoadableComponent />;
  }
}

LazyLoad.propTypes = {
  component: PropTypes.any,
  componentName: PropTypes.string,
};

function Loading() {
  return <div>Loading...</div>;
}
