import { create } from "zustand";
import { getSuppliers } from "../actions/supplier";

const initialState = {
  suppliers: [],
};

const useSupplierStore = create((set, get) => ({
  ...initialState,
  getSuppliers: () => {
    const _suppliers = get().suppliers;
    if (_suppliers.length === 0) {
      getSuppliers();
    }
  },
  setSuppliers: (supplierList) => {
    set(() => ({
      suppliers: supplierList,
    }));
  },
  addSupplier: (supplier) =>
    set((state) => ({
      suppliers: [...state.suppliers, supplier],
    })),
  deleteSupplier: (supplierId) =>
    set((state) => ({
      suppliers: state.suppliers.filter(
        (supplier) => supplier.supplier_id !== supplierId
      ),
    })),
  addBulkSupplier: (supplierList) =>
    set((state) => {
      const newSuppliers = [...state.suppliers, ...supplierList];
      return { suppliers: newSuppliers };
    }),
  updateSupplier: (updatedSupplier) =>
    set((state) => ({
      suppliers: state.suppliers.map((supplier) =>
        supplier.supplier_id === updatedSupplier.supplier_id
          ? { ...supplier, ...updatedSupplier }
          : supplier
      ),
    })),
  reset: () => set(() => ({ ...initialState })),
}));

export { useSupplierStore };
