import { ItemCatalogueService } from "../service/item_catalogues.service";
import { useItemCatalogueStore } from "../store";
import { showError } from "../widgets";

export function getItems() {
  const setItems = useItemCatalogueStore.getState().setItems;
  ItemCatalogueService.getItemsData()
    .then((data) => {
      setItems(data);
    })
    .catch((err) => showError("Error in fetching supplier data"));
}
