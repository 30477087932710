import { post, get, put, deleteReq } from "./service";

export const SupplierService = {
  createSupplier(data) {
    return post(`/supplier`, { body: data });
  },
  getSupplierData() {
    return get(`/supplier`);
  },
  updateSupplierData(supplier_id, data) {
    return put(`/supplier/${supplier_id}`, { body: data });
  },
  deleteSupplier(supplier_id) {
    return deleteReq(`/supplier/${supplier_id}`);
  },
  createBulkSupplier(supplier_list) {
    return post(`/supplier/bulk`, { body: supplier_list });
  },
};
