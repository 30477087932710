import React, { useEffect, useState } from "react";
import { PageContainer } from "../container";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  BUTTON_TYPE,
  // Checkbox,
  Password,
  Form,
  Field,
  showError,
} from "../../shared/widgets";
import { UserService } from "../../shared/service";
import { useAccountStore, useUserStore } from "../../shared/store";
import { getItem, setItem } from "../../shared/utils";
import { TABLES } from "../../shared/constants/tables";

import styles from "./resetpassword.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export function ResetPasswordPage() {
  const { loading } = useAccountStore((state) => ({
    loading: state.isLoading,
  }));

  let history = useHistory();
  let params = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [tenantId, setTenantId] = useState();
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    const tenantId = params.tenant_id;
    const token = params.token;
    const userId = params.userId;
    setTenantId(tenantId);
    setToken(token);
    setUserId(userId);
  }, [params]);

  useEffect(() => {
    if (tenantId && token && userId) {
      useAccountStore.getState().setLoading(true);
      UserService.resetPassword(tenantId, token, userId)
        .then((res) => setShowForm(true))
        .catch((err) => setShowForm(false))
        .finally(() => useAccountStore.getState().setLoading(false));
    }
  }, [tenantId, token, userId]);

  const onFinish = (values) => {
    values.confirm && Reflect.deleteProperty(values, "confirm");
    useAccountStore.getState().setLoading(true);
    UserService.updateUserPassword(tenantId, userId, values)
      .then((response) => {
        console.log(response);
        history.push("/");
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        // passwordForm.resetFields();
        // setRestorePassword(false);
        useAccountStore.getState().setLoading(false);
      });
  };

  return (
    <>
      <PageContainer>
        {!loading &&
          (showForm ? (
            <>
              <Form
                name="normal_login"
                className={styles.resetPasswordForm}
                initialValues={{
                  showPassword: showPassword,
                }}
                // onFieldsChange={onFieldsChange}
                onFinish={onFinish}
              >
                <Field>
                  <div className={styles.headerContent}>
                    <div className="header600">Reset your password</div>
                    <div className="tiny100">
                      Ensure your new password's character length is in between
                      8 - 16. It should contain atleast one number (0-9) and one
                      charater (a-z).
                    </div>
                  </div>
                  <div className="header600"></div>
                </Field>
                <Field
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 8,
                      message: "Should be minimum of 8 characters",
                    },
                    {
                      max: 100,
                      message: "Should not be more than 100 characters",
                    },
                  ]}
                  hasFeedback
                >
                  <Password placeholder="Password" />
                </Field>
                <Field
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Password placeholder="Confirm password" />
                </Field>
                <Field>
                  <Button
                    type={BUTTON_TYPE.PRIMARY}
                    htmlType="submit"
                    block={true}
                  >
                    Reset password
                  </Button>
                </Field>
              </Form>
            </>
          ) : (
            <div
              style={{
                display: "grid",
                rowGap: "4px",
                justifyContent: "center",
              }}
            >
              <p className="textsm400">
                Link has already been used to reset the password
              </p>
              <p className="textsm400">
                If you want to reset your password again,{" "}
                <Link to="/forgotpassword">click here</Link>
              </p>
            </div>
          ))}
      </PageContainer>
    </>
  );
}
