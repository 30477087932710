export const ALL_FEATURES = {
  addUser: "add_user",
  editUser: "update_user",
  deleteUser: "delete_user",
  addTeam: 'add_team',
  deleteTeam: "delete_team",
  editTeam:"editTeam",
  requestPasswordReset: "request_password_reset",
  changeUserPassword: "change_user_password",
  adminDashboard: "admin_dashboard",
  workflowPublish: "workflow_publish",
  workflowChange: "workflow_change",
  formChange: "form_change",
  formPublish: "form_publish",
  moveTicket: "move_ticket",
  openTicket: "open_ticket",
  createTicket: "create_ticket",

};

export const ALL_ROLES = {
  admin: {
    id: "admin",
    name: "Admin",
    permissions: { ...ALL_FEATURES },
  },
  user: {
    id: "user",
    name: "User",
    permissions: {
      moveTicket: "move_ticket",
      openTicket: "open_ticket",
      createTicket: "create_ticket",
    },
  },
};

// export const ROLES_MAPPED = {
//   super_admin: {
//     ...Roles,
//   },
//   user: {},
// };
