import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SignupPage,
  ActivateAccountPage,
  ActivateUserAccountPage,
} from "./unauth";
import { Spin } from "./shared/widgets";
import { useAccountStore } from "./shared/store";
import { onLoad } from "./shared/actions/onload";
import { LazyLoad } from "./shared/components";

import { PrivateRoute } from "./auth";
// import { FormRuntime } from "./shared/components/form_runtime_final/index.jsx";

import "./App.css";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  let { isLoading } = useAccountStore((state) => ({
    isLoading: state.isLoading,
  }));
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <div className="App">
      <Spin spinning={isLoading} wrapperClassName="mainLoader">
        <Router>
          <Switch>
            {/* <Route path="/login">
              <FormRuntime />
            </Route> */}
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/public/form/:account_id/:flow_id/:item_id/:form_type/:public_form_id">
              <LazyLoad
                component={() => import("./flow/form")}
                componentName={"CardFormRuntime"}
              />
            </Route>
            {/* <Route path="/public/form/:flow_id">
              <LazyLoad
                component={() => import("./flow/form")}
                componentName={"CreatePublicForm"}
              />
            </Route> */}
            <Route path="/resetpassword/:tenant_id/:token/:userId">
              <ResetPasswordPage />
            </Route>
            <Route path="/activate/:userId/:key">
              <ActivateAccountPage />
            </Route>
            <Route path="/user/activate/:userId/:key">
              <ActivateUserAccountPage />
            </Route>
            <Route path="/forgotpassword">
              <ForgotPasswordPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>

            <Route path="/">
              <PrivateRoute />
            </Route>
          </Switch>
        </Router>
      </Spin>
    </div>
  );
}

export default App;
