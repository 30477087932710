import { ItemService, WorkflowService } from "../service";
import {
  useAccountStore,
  useFlowBuilderStore,
  useFlowStore,
  useFormStore,
} from "../store";
import { showError } from "../widgets";

export function getFlowData(id) {
  const setFlowData = useFlowStore.getState().setFlowData;
  ItemService.listItems(id)
    .then((res) => {
      if (res) {
        setFlowData(res);
      }
    })
    .catch((err) => showError("Failed to fetch items"));
}

export function getFlowPosition(id) {
  const setPositionData = useFlowStore.getState().setPositionData;
  ItemService.listItems(id)
    .then((data) => {
      if (data) {
        setPositionData(data);
      }
    })
    .catch((err) => showError("Failed to fetch positions"));
}

export function getFlowSchema(id) {
  const setSchemaData = useFlowStore.getState().setSchemaData;
  WorkflowService.getLiveMetadata(id)
    .then((data) => {
      if (data) {
        setSchemaData(data);
      }
    })
    .catch((err) => showError("Failed to load live metadata"));
}

export function getDraftFlowSchema(id) {
  const setFlowData = useFlowBuilderStore.getState().setFlowData;
  const setFlowId = useFlowBuilderStore.getState().setFlowId;
  const setStatus = useFlowBuilderStore.getState().setStatus;
  WorkflowService.getDraftMetadata(id)
    .then((data) => {
      if (data) {
        setFlowData(data);
        setFlowId(id);
        setStatus("draft");
      }
    })
    .catch((err) => showError("Failed to load draft metadata"));
}

export function getWorkflowStatus(id) {}

//fix this one to match new api or create new api
export function moveCard(table_id, card, toStep) {
  let backupData = useFormStore.getState().formData;
  let phases = useFlowStore.getState().phases;
  let phasesData = useFlowStore.getState().phasesData;
  let updated_positions = [];
  //remove element from current lane
  let indexOfcard = phasesData[card.current_activity].indexOf(card.id);
  let fromLane = [...phasesData[card.current_activity]];
  fromLane = fromLane.filter((item) => item !== card.id);
  let toLane = [...phasesData[toStep], card.id];
  let _updated_phased_data = {
    ...phasesData,
    [card.current_activity]: fromLane,
    [toStep]: toLane,
  };
  phases.forEach((_phase) => {
    updated_positions = [...updated_positions, ..._updated_phased_data[_phase]];
  });
  console.log(
    "moveCard function:",
    _updated_phased_data,
    fromLane,
    toLane,
    updated_positions
  );
  const movedItemIndex = updated_positions.findIndex(
    (posId) => posId === card.id
  );
  const prevItem =
    movedItemIndex > 0 ? updated_positions[movedItemIndex - 1] : undefined;
  const nextItem =
    movedItemIndex < updated_positions.length - 1
      ? updated_positions[movedItemIndex - 1]
      : undefined;

  return ItemService.updateItemPosition(table_id, card.id, {
    prev_item_id: prevItem,
    next_item_id: nextItem,
    phase_id: toStep,
  });
}
