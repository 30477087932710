import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  Dropdown,
  Menu,
  MenuItem,
  Avatar,
  EnegmaLogo,
  EnegmaLogoFull,
  Input,
  SearchIcon,
} from "../../widgets";

import {
  EnegmaIcon,
  DropDownIcon,
  ProfileIcon,
  Icon,
  Icon_Size,
} from "../../widgets";

import { removeItem, getObject, clear } from "../../utils/storage";
import { TABLES } from "../../constants/tables";
import { UserService } from "../../service/user_service";

import styles from "./styles.module.css";
import {
  useAccountStore,
  useItemCatalogueStore,
  useSupplierStore,
  useUserStore,
} from "../../store";

export function Header() {
  const history = useHistory();

  const { user } = useUserStore((state) => ({
    user: state.currentUser,
  }));

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {};

  const onHomeClick = () => {
    history.push(`/`);
  };

  const onClick = ({ key }) => {
    if (key === "1") {
      history.push("/admin");
    } else if (key == "2") {
      history.push("/account");
    } else if (key === "3") {
      clear();
      useAccountStore.getState().reset();
      useUserStore.getState().reset();
      useSupplierStore.getState().reset();
      useItemCatalogueStore.getState().reset();
      UserService.logoutUser()
        .then(() => {
          console.log("Logging out...");
          history.push("/login");
        })
        .catch((err) => console.log(err.message));
    }
  };

  const AddMenu = (
    <Menu onClick={onClick}>
      <MenuItem key="1">
        <span className={"p200"}>Admin Dashboard</span>
      </MenuItem>
      <MenuItem key="2">
        <span className={"p200"}>Account Settings</span>
      </MenuItem>
      <MenuItem key="3">
        <span className={"p200"}>Logout</span>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={styles.header}>
      <div className={styles.logo} onClick={onHomeClick}>
        {/* <Icon src={EnegmaLogo} size={Icon_Size.Icon32} />
        <span>Enegma</span> */}
        <Icon src={EnegmaLogoFull} size={Icon_Size.Icon18} />
      </div>
      {/* <div className={styles.searchBox}>
        <Input
          placeholder={"Search everything"}
          size="large"
          prefix={<Icon src={SearchIcon} />}
        />
      </div> */}
      <div>
        <Dropdown
          overlay={AddMenu}
          key="2"
          placement="bottomRight"
          trigger={["click"]}
        >
          <div className={styles.profileMenu}>
            <div className={styles.profileIcon}>
              {user && user.name && user.name[0] ? (
                <Avatar style={{ backgroundColor: `var(--blue500)` }}>
                  {user.name[0]}
                </Avatar>
              ) : (
                <Icon src={ProfileIcon} className={styles.profileImage} />
              )}
            </div>
            <div className={styles.dropdownIcon}>
              <Icon src={DropDownIcon} size={Icon_Size.Icon12} />
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
