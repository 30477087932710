import { post, get, put, deleteReq } from "./service";

export const ItemCatalogueService = {
  createItem(data) {
    return post(`/item`, { body: data });
  },
  deleteItem(item_id) {
    return post(`/item/delete-item/${item_id}`, { body: {} });
  },
  getItemsData() {
    return get(`/item/all`);
  },
  updateItemData(item_id, data) {
    return put(`/item/${item_id}`, { body: data });
  },
  createBulkItems(items_list) {
    return post(`/item/bulk`, { body: items_list });
  },
};
