import React from "react";
import { Dropdown as AntDropdown } from "antd";
import { Icon, DropDownIcon } from "../icon";
import { Button, BUTTON_TYPE } from "../button";
// import 'antd/es/dropdown/style/css';
import styles from "./dropdown.module.css";

export function Dropdown({ children, onSelect, ...props }) {
  const onUpdate = (value) => {
    onSelect(value);
  };
  return (
    <AntDropdown onChange={(value) => onUpdate(value)} {...props}>
      {children}
    </AntDropdown>
  );
}

export function EnegmaDropdown({
  children,
  overlay,
  onSelect,
  trigger = "click",
  ...props
}) {
  return (
    <Dropdown overlay={overlay} trigger={trigger} {...props}>
      <Button type={BUTTON_TYPE.SECONDARY} className={styles.dropdownBtn}>
        <span>{children}</span>
        <Icon src={DropDownIcon} />
      </Button>
    </Dropdown>
  );
}
