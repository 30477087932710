import { get } from "./service";

export const AdminService = {
  getSupplierRFQInfo(requestType) {
    return get(`/supplier_performance/raised_rfqs?request_type=${requestType}`);
  },
  getSupplierQuotesInfo(requestType) {
    return get(
      `/supplier_performance/received_quotes?request_type=${requestType}`
    );
  },
  getSupplierPOInfo(requestType) {
    return get(`/supplier_performance/raised_pos?request_type=${requestType}`);
  },
  getSupplierDeliveryInfo(requestType) {
    return get(
      `/supplier_performance/received_deliveries?request_type=${requestType}`
    );
  },
  getSupplierInvoiceInfo(requestType) {
    return get(
      `/supplier_performance/raised_invoices?request_type=${requestType}`
    );
  },
  getFailedQuotesInfo(phases) {
    return get(`/supplier_performance/failed_quotes?phases=${phases}`);
  },
  getFailedDeliveriesInfo(phases) {
    return get(`/supplier_performance/failed_deliveries?phases=${phases}`);
  },
  getFailedInvoicesInfo(phases) {
    return get(`/supplier_performance/failed_invoices?phases=${phases}`);
  },
  getSuppliersTableInfo() {
    return get("/supplier_performance/suppliers_info");
  },
};
