import { create } from "zustand";
import { getItems } from "../actions/item_catalogues";

const initialState = {
  items: [],
};

const useItemCatalogueStore = create((set, get) => ({
  ...initialState,
  getItems: () => {
    const _items = get().items;
    if (_items.length === 0) {
      getItems();
    }
  },
  setItems: (itemsList) => {
    set(() => ({
      items: itemsList,
    }));
  },

  reset: () => set(() => ({ ...initialState })),
}));

export { useItemCatalogueStore };
