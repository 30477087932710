import { post, get, put, deleteReq } from "./service";
import { getObject } from "../utils/storage";
import { TABLES } from "../constants/tables";

export const UserService = {
  signUp(data) {
    data.password = btoa(data.password);
    return post("/signup", { body: data }, true);
  },
  signIn(data) {
    data.password = btoa(data.password);

    return post("/login", { body: data });
  },
  logoutUser() {
    return post("/logout", {});
  },
  sendPasswordResetMail(data) {
    return post("/request-password-reset", { body: data }, true);
  },
  resetPassword(tenant_id, token, user_id) {
    return get(`/reset-password/${tenant_id}/${token}/${user_id}`);
  },
  updateUserPassword(tenant_id, user_id, data) {
    data.password = btoa(data.password);

    return put(
      `/update-password/${tenant_id}/${user_id}`,
      { body: data },
      true
    );
  },
  resendEmail(data) {
    return post("/user/resend_activate_email", { body: data }, true);
  },
  activate(data) {
    return post("/user/activate", { body: data }, true);
  },
  getUser(user_id) {
    return get(`/user/get-user/${user_id}`);
  },
  updateUserName(user_id, data) {
    return put(`/user/update-name/${user_id}`, { body: data }, true);
  },
  getMembers(pageNo, pageSize) {
    return get(`/user/get-members?page_no=${pageNo}&page_size=${pageSize}`);
  },
  addMember(data) {
    data.password = btoa(data.password);

    return post("/user/add-member", { body: data }, true);
  },
  updateMember(member_id, data) {
    data.password = btoa(data.password);

    return put(`/user/update-member/${member_id}`, { body: data }, true);
  },
  deleteMember(member_id) {
    return deleteReq(`/user/delete-member/${member_id}`, {}, true);
  },
  updateRoles(data) {
    return post("/user/update-roles", { body: data }, true);
  },
  availableRoles() {
    return get("/user/available-roles");
  },
};
